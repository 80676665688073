<template>
	<b-row>
		<b-col cols="12">
			<!-- 1 -->
			<b-card no-body class="mb-4">
				<!-- Search Control -->
				<b-card-body class="p-3 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-cities @click="titleModal = 'CREATE'">Add</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table
					style="min-height: 250px;"
					show-empty
					responsive
					small
					class="mb-0"
					head-variant="light"
					:items="cities"
					:filter="filter"
					:fields="fields"
					hover
				>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>
					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-cities @click="editCity(data.item)">Edit</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group
						label="Per page"
						label-for="per-page-select"
						label-cols-sm="6"
						label-cols-md="4"
						label-cols-lg="9"
						label-size="sm"
						label-class="fw-medium"
						class="mb-0"
					>
						<b-form-select @change="getCities" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination @input="getCities" v-model="currentPage" :total-rows="totalCities" :per-page="perPage" align="fill" class="my-0"></b-pagination>
					</div>
				</div>
				<!-- End Section -->
			</b-card>
		</b-col>

		<!-- Modal -->
		<b-modal id="modal-cities" hide-footer centered :title="titleModal" @close="closeModal">
			<b-form @submit.prevent="createCity">
				<b-form-group label="Description" label-class="fw-medium mb-1" label-for="name">
					<b-form-input id="name" type="text" placeholder="City here" required v-model="name" autocomplete="off"></b-form-input>
				</b-form-group>
				<b-form-group label="States" label-class="fw-medium mb-1" label-for="statesName">
					<b-form-select v-model="state" class="mb-3" required>
						<b-form-select-option :value="null">Please select an option</b-form-select-option>
						<b-form-select-option :value="state.id" v-for="state in states" :key="state.id">{{ state.name }}</b-form-select-option>
					</b-form-select>
				</b-form-group>

				<b-button block :disabled="loadingCity" variant="primary" type="submit">
					<b-spinner small v-if="loadingCity"></b-spinner>
					<span v-else>Save</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-remove" size="sm" hide-footer centered title="Delete City">
			<b-card-text>
				Are you sure to want to remove this city?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<!-- <b-button variant="danger" class="mr-2" @click="removeCity">Yes</b-button> -->
				<b-button variant="danger" class="mr-2">Yes</b-button>
				<!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
				<b-button variant="secondary">No</b-button>
			</div>
		</b-modal>
		<!-- End Section -->
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "cities",

	data: () => ({
		page: {
			title: "cities",
		},
		filter: null,
		fields: [
			{
				key: "id",
				label: "No",
				sortable: true,
			},
			{
				key: "name",
				label: "City",
				sortable: true,
			},
			{
				key: "stateName",
				label: "State",
				sortable: true,
			},
			{
				key: "action",
				label: "Actions",
				sortable: false,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		titleModal: "Create City",
		loadingCity: false,
		name: "",
		state: null,
		citySelected: {},
	}),
	components: {},
	methods: {
		clearValues() {
			this.name = "";
			this.state = null;
			this.citySelected = {};
		},
		closeModal() {
			this.isEditing = false;
			this.clearValues();
			this.titleModal = "Create City";
		},
		async getData() {
			await this.getCities();
			await this.getStates();
			await this.$store.dispatch("authorizations/get_allAuthorizations");
		},
		async getCities() {
			let data = {
				skip: this.currentPage,
				take: this.perPage,
			};
			await this.$store.dispatch("cities/get_allCities", data);
		},
		async getStates() {
			let data = {
				skip: 1,
				take: 999,
			};
			await this.$store.dispatch("states/get_allStates", data);
		},
		async editCity(cities) {
			this.clearValues();
			const citiesData = await this.$store.dispatch("cities/get_cities", cities.id);
			this.citySelected = citiesData;
			this.isEditing = true;
			this.name = citiesData.name;
			this.state = citiesData.stateId; // brings the previous state value
			this.state = citiesData.stateId; // copy the new state value
			this.titleModal = "EDIT";
		},
		async createCity() {
			this.loadingCity = true;
			let data = {
				name: this.name,
				stateId: this.state,
			};

			if (this.isEditing) {
				(data.id = this.citySelected.id), await this.$store.dispatch("cities/update_cities", data);
			} else {
				await this.$store.dispatch("cities/create_cities", data);
			}

			await this.getCities();
			this.clearValues();

			this.$bvModal.hide("modal-cities");
			this.loadingCity = false;
		},
	},
	computed: {
		...mapGetters({
			cities: "cities/getCities",
			totalCities: "cities/getTotalCities",
			authorizations: "authorizations/getAuthorizations",
			states: "states/getStates",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
